import React, {useState} from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import BlogDetails from "components/Blog-details/blog-details";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const BlogDetailsDark = (props) => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [theme, setTheme] = useState('Dark');

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem('theme');

    if(storage && storage != '') {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }
  }, [navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if(themeMode == "Dark") {
      setTheme('themeL');
      bodyEl.classList.add("themeL");
      localStorage.setItem('theme', "themeL");
    }else {
      setTheme('Dark');
      bodyEl.classList.remove("themeL");
      localStorage.removeItem('theme')
    }
  }

  return (
    <DarkTheme>
      <LoadingScreen/>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} theme={theme} themeModeChange={themeModeChange} />
      <PageHeader
        title="Blog Details."
        paragraph="All the most current news and events of our creative team."
      />
      <BlogDetails blog={"blog"} propdata={props.pageContext} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = (props) => {

  const liveUrl = typeof window !== "undefined" ? window.location.href : "";
  return (
    <>
    <SEO title={props?.pageContext?.node?.seo?.title}
    description={props?.pageContext?.node?.seo?.metaDesc}
    canonical={liveUrl}
    />
    </>
  )
}

export default BlogDetailsDark;
