import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import {
  gql,
  useMutation,
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
const assignIdsToHeadings = (content) => {
  if (typeof window !== "undefined") {
    const doc = new DOMParser().parseFromString(content, "text/html");
    const headings = doc.querySelectorAll(".wp-block-heading");

    Array.from(headings).forEach((heading, index) => {
      const id = `heading-${index}`;
      heading.id = id;
    });
    return doc.body.innerHTML;
  } else {
    return content;
  }
};
const BlogDetails = ({ theme, propdata }) => {
  const [headingIds, setHeadingIds] = useState([]);

  useEffect(() => {
    const assignHeadingIds = () => {
      const headings = document.querySelectorAll(".wp-block-heading");
      const ids = Array.from(headings).map((heading, index) => {
        const id = `heading-${index}`;
        heading.id = id;
        return id;
      });
      setHeadingIds(ids);
    };
    // Call the function after the component has rendered
    assignHeadingIds();
  }, [propdata?.node?.content]);
  const APOLLO_CLIENT = new ApolloClient({
    uri: process.env.GATSBY_API_URL,
    cache: new InMemoryCache(),
  });

  const BlogDetailPage = () => {
    const galleryImages = propdata?.node?.galleryImages
      ? JSON.parse(propdata?.node?.galleryImages)
      : "";

    function validateEmail(value) {
      let error;
      if (!value) {
        error = "Required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = "Invalid email address";
      }
      return error;
    }

    const getFullDate = (isoDate) => {
      const monthNames = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      var date = new Date(isoDate);
      return (
        date.getDate() +
        " " +
        monthNames[date.getMonth()] +
        " " +
        date.getFullYear()
      );
    };

    const [commentValue, setCommentValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [nameValue, setNameValue] = useState("");

    const COMMENT_MUTATION = gql`
      mutation CreateCommentMutation(
        $clientMutationId: String!
        $content: String!
        $author: String!
        $authorEmail: String!
        $commentOn: Int!
      ) {
        createComment(
          input: {
            clientMutationId: $clientMutationId
            content: $content
            author: $author
            authorEmail: $authorEmail
            commentOn: $commentOn
          }
        ) {
          success
        }
      }
    `;

    const [mutateFunction, { data, loading, error }] =
      useMutation(COMMENT_MUTATION);

    return (
      <section className="blog-pg single section-padding pt-0">
        <div className="container">
          {/* Floating menu */}
          <div className="flex-row-reverse row justify-content-center">
            <div className="col-lg-3 col-md-3">
              <div className="floating-menu">
                <h6 className="float-head">Contents:</h6>
                <ul>
                  {headingIds.map((id, index) => {
                    const headings = propdata?.node?.content?.match(
                      /<h[1-6][^>]*>.*?<\/h[1-6]>/g
                    );
                    const headingText = headings?.[index]?.replace(
                      /<\/?[^>]+(>|$)/g,
                      ""
                    ); // Remove HTML tags
                    return (
                      <>
                        <li key={id}>
                          <a href={`#${id}`}>{headingText}</a>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="post">
                <div className="img">
                  {/* <img src={propdata?.node?.featuredImage?.node?.guid} alt="" /> */}
                </div>
                <div className="content">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="cont">
                        <h4 className="extra-title">{propdata?.node?.title}</h4>
                        <div className="spacial">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: assignIdsToHeadings(
                                propdata?.node?.content
                              ),
                            }}
                          />
                        </div>

                        <div className="row">
                          {galleryImages &&
                            galleryImages.map((img, k) => (
                              <div className="col-md-6" key={k}>
                                <div className="mb-10">
                                  <img loading ="lazy" src={img} alt="" />
                                </div>
                              </div>
                            ))}
                        </div>

                        <div className="share-info">
                          {/* <div className="social">
                            <a href="#0">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="#0">
                              <i className="fab fa-twitter"></i>
                            </a>
                            <a href="#0">
                              <i className="fab fa-behance"></i>
                            </a>
                          </div> */}
                          <div className="tags">
                            {propdata?.node?.tags?.edges?.map((tag, index) => (
                              <Link
                                key={index}
                                to={`/tag/${tag.node.slug}`}
                                className="tag"
                              >
                                <span className="">
                                  {tag.node.name}
                                  {propdata?.node?.tags?.edges.length !==
                                  index + 1
                                    ? ", "
                                    : ""}
                                </span>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {propdata?.node?.comments &&
                  propdata?.node?.comments?.edges?.length > 0 && (
                    <div className="comments-area">
                      <h5>Comments :</h5>
                      {propdata?.node?.comments?.edges
                        ?.slice(0, 4)
                        .map((commentVal) => (
                          <div className="item">
                            <div className="comment-img">
                              <img loading ="lazy"
                                src="/img/avatar_light_.png"
                                alt=""
                              />
                            </div>
                            <div className="info">
                              <h6>
                                {commentVal?.node?.author?.node?.name} -{" "}
                                <span>
                                  {" "}
                                  {getFullDate(commentVal?.node?.date)}
                                </span>
                              </h6>
                              <p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: commentVal?.node?.content,
                                  }}
                                />
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}

                {/* <div className="comment-form" id="comment-form">
                  <h5>Add Comment :</h5>
                  <div className="form">
                    <form
                      onSubmit={async (event) => {
                        event.preventDefault();
                        mutateFunction({
                          variables: {
                            clientMutationId: "example",
                            content: commentValue,
                            author: nameValue,
                            authorEmail: emailValue,
                            commentOn: propdata.node.databaseId,
                          },
                        });
                      }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              as="textarea"
                              placeholder="Your Comment"
                              name="comment"
                              onChange={(event) => {
                                setCommentValue(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Your Name"
                              name="name"
                              onChange={(event) => {
                                setNameValue(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              validate={validateEmail}
                              placeholder="Your Email"
                              name="email"
                              onChange={(event) => {
                                setEmailValue(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group text-center">
                            <button
                              type="submit"
                              className={`nb butn ${
                                theme
                                  ? theme === "light"
                                    ? "dark"
                                    : ""
                                  : "light"
                              } curve full-width`}
                            >
                              Comment
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {loading && <p>Loading ...</p>}
                  {error && (
                    <p>
                      An unknown error has occured, please try again later ...
                    </p>
                  )}
                  {data && data.createComment.success && (
                    <p>Your comment added successfully!</p>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  return (
    <ApolloProvider client={APOLLO_CLIENT}>
      <BlogDetailPage />
    </ApolloProvider>
  );
};

export default BlogDetails;
